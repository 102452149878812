<template>
  <a-table rowKey="id" size="small" :columns="columns" :dataSource="items" :pagination="false" :scroll="{ y: 480 }">
    <template slot="status" slot-scope="value, item">
      <a-badge v-if="item.is_valid" status="success" text="有效" />
      <a-badge v-else status="error" text="无效" />
    </template>

    <template slot="error_messages" slot-scope="value">
      <a-tag v-for="(error_message, index) in value" color="red" :key="index">{{ error_message }}</a-tag>
    </template>
  </a-table>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "运单号",
          dataIndex: "tracking_number",
        },
        {
          title: "重量(kg)",
          dataIndex: "weight",
          width: 100,
        },
        {
          title: "长度(mm)",
          dataIndex: "length",
          width: 100,
        },
        {
          title: "宽度(mm)",
          dataIndex: "width",
          width: 100,
        },
        {
          title: "高度(mm)",
          dataIndex: "height",
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 80,
        },
        {
          title: "错误信息",
          dataIndex: "error_messages",
          scopedSlots: { customRender: "error_messages" },
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped></style>
